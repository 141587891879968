import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/AuthContext';
import SignIn from '../../SignIn/SignIn';
import { showJoinNowPage } from '../../../services/navigation/navigation.service';
import { isWeb } from '../../../services/util-service/util.service';
import ModalContainer from '../../ModalContainer/ModalContainer';

import './MobileUserActions.scss';

const MobileUserActions = () => {
  const { t } = useTranslation();
  const { userLoggedIn } = useAuthContext();

  if (userLoggedIn || isWeb()) return null;

  return (
    <div className="MobileUserActions">
      <span onClick={showJoinNowPage} className='text-uppercase'>{t('MobileUserActions.joinNow')}</span>
      &nbsp;
      &nbsp;
      &nbsp;
      <ModalContainer buttonText='LOGIN'>
        <SignIn />
      </ModalContainer>
    </div>
  );
};

export default MobileUserActions;
